$(function () {
    startCarousel();
})
function startCarousel() {
    const width = $(window).width();

    $(".js-roof").owlCarousel({
        dragEndSpeed: 300,
        dotsSpeed: 300,
        navSpeed: 300,
        loop: true,
        navText: [`<svg width="52" height="16" viewBox="0 0 52 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M52 8L2 8M2 8L8.40507 15M2 8L8.40507 1" stroke="white" stroke-width="2" />
</svg>`, `<svg width="52" height="16" viewBox="0 0 52 16" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M52 8L2 8M2 8L8.40507 15M2 8L8.40507 1" stroke="white" stroke-width="2" />
</svg>`],
        touchDrag: true,
        center: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                autoWidth: false,
                items: 1,
                margin: 0,
            },
            768: {
                margin: 30,
                items: 2,
            },
            1024: {
                items: 3,
            }
        }
    });
}

$(window).on('resize', () => {
    startCarousel();
});

