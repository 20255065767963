import { hexToCSSFilter } from 'hex-to-css-filter';

$(function () {
    $(document).on("click", ".js-color-scheme__colors-item", function () {
        var index = $(this).index();
        $(document).find(".js-color-scheme__colors-item").removeClass("active");
        $(this).addClass("active");
        var color = $(this).attr("data-color");
        $(document).find(".js-color-hex").text(color);

        const cssFilter = hexToCSSFilter(color).filter;
        console.log(cssFilter);

        $(document).find(".js-color-scheme__item-img img").attr("style", "filter:" + cssFilter + "")



    })
})