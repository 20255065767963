$(window).scroll(function () {
  var $sections = $('.tech__section');
  $sections.each(function (i, el) {
    var top = $(el).offset().top - 100;
    var bottom = top + $(el).height();
    var scroll = $(window).scrollTop();
    var id = $(el).attr('id');
    if (scroll > top && scroll < bottom) {
      $('.tech__nav-link').parent().removeClass('active');
      $('.tech__nav-link[href="#' + id + '"]').parent().addClass('active');

    }
  })
})
$(function () {
  $(document).on("click", ".tech__nav-link", function (event) {
    event.preventDefault();
    var id = $(this).attr('href'),
      top = $(id).offset().top - 130;

    $('body,html').animate({ scrollTop: top }, 1500);
  });
})
