$(function () {
    $(document).on("click", ".js-offices__tabs-item", function () {
        var index = $(this).index();
        $(document).find(".js-offices__tabs-item").removeClass("active");
        $(this).addClass("active");
        $(document).find(".js-offices__data-item").removeClass("active");
        $(document).find(".js-offices__data-item").eq(index).addClass("active");
    })
    if ($("#mapid").length > 0) {
        var mymap = L.map('mapid').setView([51.50493520473332, 31.29294598925316], 16);

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1,
            accessToken: 'pk.eyJ1IjoiaWdvcmVrOTYiLCJhIjoiY2s2NmZybDJtMWdjMzNscXd6ODdhZXp4dSJ9.f-E9GXYdqji6-BweueKWcg'
        }).addTo(mymap);

        var chernihiv = [51.50493520473332, 31.29294598925316];
        var priluki = [50.596462127387454, 32.37646666562516];
        var berezna = [51.57725668566221, 31.78896843455734];
        var nezhin = [51.02388018040478, 31.885903446165877];

        var marker1 = L.marker(chernihiv).addTo(mymap);
        var marker2 = L.marker(priluki).addTo(mymap);
        var marker3 = L.marker(berezna).addTo(mymap);
        var marker4 = L.marker(nezhin).addTo(mymap);


        $('#fly-chernihiv').on('click', function () {
            mymap.flyTo(chernihiv, 16);
        });
        $('#fly-priluki').on('click', function () {
            mymap.flyTo(priluki, 16);
        });
        $('#fly-berezna').on('click', function () {
            mymap.flyTo(berezna, 16);
        });
        $('#fly-nezhin').on('click', function () {
            mymap.flyTo(nezhin, 16);
        });
    }
})
