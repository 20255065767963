$(function () {
    var showroomSlider = $(".js-metal-tile-slider").owlCarousel({
        // items: 1,
        loop: true,
        nav: false,
        dots: false,
        margin: 0,
        // startPosition: 1,
        autoWidth: true,
        navElement: "div",
        navClass: ["owl-control owl-prev", "owl-control owl-next"],
        navText: ['<svg><use xlink:href="/assets/template/_assets/svg/sprite.svg#ico_next"/></svg>', '<svg><use xlink:href="/assets/template/_assets/svg/sprite.svg#ico_next"/></svg>'],
        autoplay: true,
        autoplayHoverPause: false,
        autoplayTimeout: 5000,
        autoplaySpeed: 1000,
        navSpeed: 1000,
        responsive: {
            0: {
                autoWidth: false,
                items: 1,
                dots: true,
                nav: false,
            },
            768: {
                autoWidth: true,
            }
        }
    });
})