$(function () {
  $(document).on("click", ".js-calc", function (e) {
    e.preventDefault();
    let height = $(document).find(".js-height").val().replace(",", '.');
    let dovzhina = $(document).find(".js-dovzhina").val().replace(",", '.');
    let name = $("select.js-calculator-select").children("option:selected").val();
    let zagalna = $(document).find(".js-corrugated__item[data-name =" + name + "").find(".js-zagalna").text();
    let korisna = $(document).find(".js-corrugated__item[data-name =" + name + "").find(".js-korisna").text();

    let result1 = Math.ceil(dovzhina / (korisna / 1000))

    let result2 = result1 * height * (zagalna / 1000);
    let result = result2.toFixed(2)

    $(document).find(".js-calculator__data-info").addClass("active");
    $(document).find(".js-quantity").text(result1)
    $(document).find(".js-length").text(result)
  })

  $(document).on("click", ".js-calculator__tabs-item", function () {
    var index = $(this).index();
    $(document).find(".js-calculator__tabs-item").removeClass("active");
    $(this).addClass("active");
    $(document).find(".js-calculator__list-item").removeClass("active");
    $(document).find(".js-calculator__list-item").eq(index).addClass("active");
  })

  $(document).on("click", ".js-result", function (e) {
    e.preventDefault();
    let height = $(this).closest(".form").find(".js-height").val().replace(",", '.');
    let longitude = $(this).closest(".form").find(".js-longitude").val().replace(",", '.');
    let distance = $(this).closest(".form").find(".js-distance").val().replace(",", '.');
    let index = $(this).closest(".js-calculator__list-item").index()
    let wide = $(document).find(".js-fence__item").eq(index).find(".js-wide").text();

    let result1 = Math.ceil((longitude * 1000) / (+distance + +wide));
    let result2 = (result1 * height) / 1000;
    let result = result2.toFixed(2)

    $(this).closest(".form").find(".js-calculator__data-info").addClass("active");
    $(this).closest(".form").find(".js-quantity").text(result1);
    $(this).closest(".form").find(".js-length").text(result);
  })
})