$(function () {
    let screenWidth = $(window).width();
    if (screenWidth > 767) {
        $('.nav__submenu-item').mouseenter(function () {
            if ($(this).closest('.nav__item_hassmallmenu').length == 0) {
                $('.nav__submenu-item').removeClass('active');
            } else {
                $('.nav__item_hassmallmenu .nav__submenu-item').removeClass('active');
            }
            $(this).addClass('active');
        });
    }
})