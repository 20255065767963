$(function () {
    $(".js-header").sticky({
        zIndex: 60,
    })
    const $menu = $('.js-menu');

    $(document).mouseup(e => {
        if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
            $menu.removeClass('active');
            $('.js-menu-btn').removeClass("active");
            $("body").removeClass("open");
            $(".js-substrate").removeClass("active");
        }
    });

    $('.js-menu-btn').on('click', function () {
        $(this).toggleClass("active");
        $menu.toggleClass('active');
        $("body").toggleClass("open");
        $(".js-substrate").toggleClass("active");
        $(document).find(".js-menu__wrap").addClass("active");
        $(document).find(".js-menu__form").removeClass("active");
    });

    $(document).on("click", ".js-header__dropdown-item", function () {
        var addNmbr = $(this).index();
        var cityName = $(this).text();
        $(".js-header__dropdown-item").removeClass("active");
        $(this).addClass("active");
        $(document).find(".js-header__dropdown-table").removeClass("active");
        $(document).find(".js-header__dropdown-table").eq(addNmbr).addClass("active");
        $(".js-city").text(cityName);
    })
    $(document).on("click", ".js-open-form", function () {
        $(document).find(".js-menu__wrap").removeClass("active");
        $(document).find(".js-menu__form").addClass("active");
    })

    $(document).on("click", ".js-menu-link", function () {
        $('.js-menu-btn').removeClass("active");
        $("body").removeClass("open");
        $(".js-substrate").removeClass("active");
        $('.js-menu').removeClass("active");
    })


})