$(".selective__item-wrap").matchHeight({ byRow: true })
$(function () {
    $(document).on("click", ".js-selective__item", function () {
        $(".js-selective__item").removeClass("active");
        $(this).addClass("active");
    })
    $(document).on("click", ".js-selective__dropdown-item", function () {
        var color = $(this).attr("data-color");
        var filter = $(this).attr("data-filter");
        $(".js-selective__dropdown-item").removeClass("active");
        $(this).addClass("active");
        $(this).closest(".js-selective__item").find(".js-selective__dropdown-hex").text(color);


        $(this).closest(".js-selective__item").find(".js-selective__item-img").find(".main").attr("style", "filter:" + filter + "")
    })


})