$(function () {
    $(document).on("click", ".js-color-scale__colors-item", function () {
        var index = $(this).index();
        $(document).find(".js-color-scale__colors-item").removeClass("active");
        $(this).addClass("active");
        var color = $(this).attr("data-color");
        var filter = $(this).attr("data-filter");

        $(document).find(".js-hex").text(color);


        $(document).find(".js-metal img").attr("style", "filter:" + filter + "")


        if (index == 0) {
            $(document).find(".js-color-prev").attr("disabled", true);
            $(document).find(".js-color-next").attr("disabled", false);
        }
        else if (index == $(".js-color-scale__colors-item").length - 1) {
            $(document).find(".js-color-next").attr("disabled", true);
            $(document).find(".js-color-prev").attr("disabled", false);
        }
        else {
            $(document).find(".js-color-next").attr("disabled", false);
            $(document).find(".js-color-prev").attr("disabled", false);
        }
    })
    $(document).on("click", ".js-color-prev", function () {
        var index = $(document).find(".js-color-scale__colors-item.active").index();
        if (index > 0) {
            $(document).find(".js-color-scale__colors-item").removeClass("active");
            $(document).find(".js-color-scale__colors-item").eq(index - 1).trigger("click");
        }
        else {
            $(document).find(".js-color-prev").attr("disabled", true);
        }
    })
    $(document).on("click", ".js-color-next", function () {
        var index = $(document).find(".js-color-scale__colors-item.active").index();
        if (index < (".js-color-scale__colors-item").length - 1) {
            $(document).find(".js-color-scale__colors-item").removeClass("active");
            $(document).find(".js-color-scale__colors-item").eq(index + 1).trigger("click");
        }
        else {
            $(document).find(".js-color-next").attr("disabled", true);
        }
    })


    $(document).on("click", ".js-color-btn", function (e) {
        e.preventDefault();
        $(document).find(".js-menu-btn").trigger("click");
        $(document).find(".js-open-form").trigger("click");
    })
})