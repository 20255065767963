import "%modules%/footer/footer";
import "%modules%/header/header";
import "%modules%/nav/nav";
import "%modules%/offices/offices";
import "%modules%/roof/roof";
import "%modules%/form/form";
import "%modules%/metal-tile/metal-tile";
import "%modules%/selective/selective";
import "%modules%/select/select";
import "%modules%/calculator/calculator";
import "%modules%/color-scale/color-scale";
import "%modules%/tech/tech";
import "%modules%/color-scheme/color-scheme";
